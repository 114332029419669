const header = {
  homepage: 'https://www.haylzrandom.co.uk',
  logo: '/images/logo/haylzpic.png',
};

const about = {
  name: 'Hayley McCafferty',
  role: 'Full Stack Developer',
  // description: `Experienced professional embarking on a career change into web development. Through training and side projects, I have developed strong skills in HTML5, CSS3, JavaScript and React. \n\nI am eager to transition into a role that provides greater fulfillment and the opportunity to apply these skills. With five years of experience working with databases and ETL procedures, and a previous role as a web developer six years ago, I can bring a diverse skill set to the table.\n\nDespite being made redundant from my previous job due to cost savings, I am excited to pursue my passion and my ability to contribute to a dynamic team. \n\nContact me if my skills may be of interest!`,
  description: `Strongly motivated full stack developer with a drive for web development after a decade-long career in technology. Transitioning from a five-year focus on databases I am seeking a new challenge in the world of full stack development.
	
	If you want to have a chat then please do contact me!`,
  social: {
    linkedin: 'https://www.linkedin.com/in/hayleymcc/',
    github: 'https://github.com/haylzrandom',
    freeCodeCamp: 'https://www.freecodecamp.org/haylzrandom',
    frontEndMentor: 'https://www.frontendmentor.io/profile/HaylzRandom',
  },
  cvFile: 'files/HayleyMcCaffertyCV.pdf',
};

const projects = [
  {
    name: 'NC News',
    description:
      'React web application that utilises a custom API that will display a collection of articles and also allow a user to vote and comment on an article. Created with React, Node.js, React Router and Axios. Deployed via Netlify.',
    stack: [
      'JavaScript',
      'React',
      'Node.js',
      'React Router',
      'Axios',
      'Netlify',
    ],
    sourceCode: 'https://github.com/HaylzRandom/nc-news',
    livePreview: 'https://nc-news-haylzrandom.netlify.app/',
    image: '/images/projects/nc-news.png',
  },
  {
    name: 'NC News API',
    description:
      'Backend API project built using Express.js, Node.js and PostgreSQL that allows a front end application to access data needed for a news website such as articles, comments, users and topics.',
    stack: ['JavaScript', 'Express.js', 'Node.js', 'PostgreSQL'],
    sourceCode: 'https://github.com/HaylzRandom/nc-news-api',
    livePreview: 'https://nc-news-api-ga04.onrender.com/api',
    image: '/images/projects/nc-news-api.png',
  },
  {
    name: 'Clothing E-Commerce Store Website',
    description:
      'A clothing e-commerce website that allows users to create an account or login with a Google account, add products to their cart and checkout using Stripe API. Client-side created using React and SASS. Server-side created using Firebase for authentication and database storage.',
    stack: ['React', 'React Router', 'Redux', 'Firebase', 'Stripe', 'SCSS'],
    sourceCode: 'https://github.com/HaylzRandom/clothing-ecommerce-store',
    livePreview: 'https://beamish-palmier-a8229c.netlify.app/',
    image: '/images/projects/clothing-store.png',
  },
  {
    name: 'Angel Tech Help Desk System - MERN Project',
    description:
      'MERN stack project that allows users to login to help desk system, create tickets, edit tickets and allows admin users to create and delete users',
    stack: ['React', 'Redux', 'MongoDB', 'Node.js', 'Express.js', 'JWT'],
    sourceCode: 'https://github.com/HaylzRandom/angeltech-frontend',
    livePreview: 'https://angeltech-helpdesk.onrender.com/',
    image: '/images/projects/angeltech.png',
  },
  {
    name: 'REST Countries API with Colour Theme Switcher',
    description:
      'React project that pulls data from REST Countries API and displays on website. Users can also search for specific countries by name or by region',
    stack: [
      'React',
      'HTML5',
      'CSS3',
      'JavaScript',
      'React Router',
      'Axios',
      'API',
    ],
    sourceCode: 'https://github.com/HaylzRandom/rest-countries-api',
    livePreview: 'https://rest-countries-api-1h8n.onrender.com/',
    image: '/images/projects/rest-countries-api.png',
  },
  // {
  // 	name: 'CV Online',
  // 	description:
  // 		"React project that I created to make my custom designed CV as wasn't happy with default templates being offered by software so decided to flex my web development skills!",
  // 	stack: ['React', 'HTML5', 'CSS3', 'JavaScript'],
  // 	sourceCode: 'https://github.com/HaylzRandom/cv-online',
  // 	livePreview: 'https://haylzrandom.github.io/cv-online/',
  // 	image: '/images/projects/online-CV.png',
  // },
  {
    name: 'Calculator App with Theme Changer',
    description:
      'Frontend Mentor challenge to build a Calculator App using HTML, CSS and JavaScript',
    stack: ['HTML5', 'CSS3', 'JavaScript'],
    sourceCode: 'https://github.com/HaylzRandom/calculator-app',
    livePreview: 'https://haylzrandom.github.io/calculator-app/',
    image: '/images/projects/calculator-app.png',
  },
  // {
  // 	name: 'Sims 2 Randomiser',
  // 	description:
  // 		'Custom built randomiser to help create unique looking sims in Sims 2. Built using React.',
  // 	stack: ['React', 'HTML5', 'CSS3', 'JavaScript'],
  // 	sourceCode: 'https://github.com/HaylzRandom/sims2-randomiser',
  // 	livePreview: 'https://haylzrandom.github.io/sims2-randomiser/',
  // 	image: '/images/projects/sims2-randomiser.png',
  // },
  {
    name: 'Space Tourism Multipage Website',
    description:
      'Project to build a multipage Space Tourism website utilising different navigation styles, hover states and responsive designs',
    stack: ['HTML5', 'CSS3', 'JavaScript'],
    sourceCode:
      'https://github.com/HaylzRandom/space-tourism-multipage-website',
    livePreview:
      'https://haylzrandom.github.io/space-tourism-multipage-website/',
    image: '/images/projects/space-tourism.png',
  },
  {
    name: 'Frontend Mentor Challenges',
    description:
      'Collection of all the Frontend Mentor challenges that I have completed thus far',
    stack: ['HTML5', 'CSS3', 'JavaScript', 'React'],
    sourceCode: 'https://github.com/HaylzRandom/frontend-mentor-challenges',
    livePreview: 'https://haylzrandom.github.io/frontend-mentor-challenges/',
    image: '/images/projects/frontendmentor-challenges.png',
  },
  {
    name: 'Collection of Mini JavaScript projects',
    description:
      'Collection of 50+ JavaScript mini projects such as a random choice picker, dad jokes generator, sticky navbar, an image carousel and more...',
    stack: ['HTML5', 'CSS3', 'JavaScript', 'axios', 'API'],
    sourceCode: 'https://github.com/HaylzRandom/mini-js-projects',
    livePreview: 'https://haylzrandom.github.io/mini-js-projects/',
    image: '/images/projects/mini-js-projects.png',
  },
  {
    name: 'Jammming Project',
    description:
      'React project that allows a user to search for songs on Spotify and create a new playlist on their account. Utilises Spotify API',
    stack: ['HTML5', 'CSS3', 'React', 'Spotify API'],
    sourceCode: 'https://github.com/HaylzRandom/jammming-prj-react',
    livePreview: 'https://jammming-prj-react.vercel.app/',
    image: '/images/projects/jammming-screenshot.png',
  },
];

const training = [
  {
    name: 'Front-End Engineer',
    description:
      'Front-end engineers work closely with designers to make websites beautiful, functional, and fast. This Career Path will teach you the technologies you need to do just that.',
    description2:
      'Technologies include: HTML5, CSS3, JavaScript, React, Redux, Testing and much more.',
    courseLink:
      'https://www.codecademy.com/learn/paths/front-end-engineer-career-path',
    certLink:
      'https://www.codecademy.com/profiles/haylzrandom/certificates/5f85dd867b67b60014ac9ea3',
    logo: '/images/training/codecademy.png',
    logoAlt: 'codecademy Logo',
  },
  {
    name: 'Complete Web Developer in 2023: Zero to Mastery',
    description:
      'Learn to code from scratch and get hired as a Web Developer in 2023. This full-stack coding bootcamp will teach you HTML, CSS, JavaScript, React, Node.js, Machine Learning & more.',
    courseLink: 'https://zerotomastery.io/courses/coding-bootcamp/',
    certLink:
      'https://drive.google.com/file/d/1Xy8R8LN962FFT9l6MoefybtOnT79gZJ4/view',
    logo: '/images/training/zTm.png',
    logoAlt: 'ZeroToMastery Logo',
  },
  {
    name: 'Complete React Developer in 2023(w/ Redux, Hooks, GraphQL): Zero to Mastery',
    description:
      'Learn from React.js experts. This is the only React JS tutorial + projects course you need to learn React, build advanced large-scale applications from scratch and get hired as a React Developer in 2023.',
    courseLink: 'https://zerotomastery.io/courses/learn-react/',
    certLink:
      'https://drive.google.com/file/d/1Pk-d2rMchQ-3qhyR5jkScbjKv6PjPG54/view',
    logo: '/images/training/zTm.png',
    logoAlt: 'ZeroToMastery Logo',
  },
  {
    name: 'Responsive Web Design',
    description:
      'Certification course covering: HTML, HTML5, CSS3, Visual Design, Accessibility, Responsive Web Design Principles, CSS Grid, CSS Flexbox',
    courseLink:
      'https://www.freecodecamp.org/learn/2022/responsive-web-design/',
    certLink:
      'https://www.freecodecamp.org/certification/haylzrandom/responsive-web-design',
    logo: '/images/training/freeCodeCamp.png',
    logoAlt: 'freeCodeCamp Logo',
  },
  {
    name: 'JavaScript Algorithms and Data Structures',
    description:
      'Fundamentals of JavaScript including variables, arrays, objects, loops and functions. Knowledge to create algorithms to manipulate strings, factorialize numbers and learn two important programming paradigms OOP and FP.',
    courseLink:
      'https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/',
    certLink:
      'https://www.freecodecamp.org/certification/haylzrandom/javascript-algorithms-and-data-structures',
    logo: '/images/training/freeCodeCamp.png',
    logoAlt: 'freeCodeCamp Logo',
  },
  {
    name: 'Front End Development Libraries',
    description:
      'Learn how to style a site quickly with Bootstrap, add logic to CSS styles and extend them with SASS. Also learn to create SPAs with React and Redux.',
    courseLink:
      'https://www.freecodecamp.org/learn/front-end-development-libraries/',
    certLink:
      'https://www.freecodecamp.org/certification/haylzrandom/front-end-development-libraries',
    logo: '/images/training/freeCodeCamp.png',
    logoAlt: 'freeCodeCamp Logo',
  },
  {
    name: 'React Front to Back 2022',
    description:
      'Learn modern React by building 4 projects including a Firebase 9 app and a full stack MERN app',
    courseLink: 'https://www.udemy.com/course/react-front-to-back-2022/',
    certLink:
      'https://www.udemy.com/certificate/UC-603615e9-b62c-4beb-a301-cd35ca301536/',
    logo: '/images/training/uDemy.png',
    logoAlt: 'uDemy Logo',
  },
  {
    name: 'Learn TypeScript',
    description:
      'Learn TypeScript, a superset of JavaScript that adds types to make the language scale!',
    courseLink: 'https://www.codecademy.com/learn/learn-typescript',
    certLink:
      'https://www.codecademy.com/profiles/haylzrandom/certificates/56fb1e71303e37b643bb1905f31c8a09',
    logo: '/images/training/codecademy.png',
    logoAlt: 'Codecademy Logo',
  },
];

const skills = [
  {
    name: 'HTML5',
    logo: '/images/skills/html5.svg',
  },
  {
    name: 'CSS3',
    logo: '/images/skills/css3.svg',
  },
  {
    name: 'JavaScript',
    logo: '/images/skills/js.svg',
  },
  {
    name: 'React',
    logo: '/images/skills/react.svg',
  },
  {
    name: 'Git',
    logo: '/images/skills/git.svg',
  },
  {
    name: 'Github',
    logo: '/images/skills/github.svg',
  },
  {
    name: 'T-SQL',
    logo: '/images/skills/sql.svg',
  },
  {
    name: 'VS Code',
    logo: '/images/skills/vscode.svg',
  },
  {
    name: 'Redux',
    logo: '/images/skills/redux.svg',
  },
  {
    name: 'Atlassian Products',
    logo: '/images/skills/atlassian.svg',
  },
  {
    name: 'Jest',
    logo: '/images/skills/jest.svg',
  },
  {
    name: 'PostgreSQL',
    logo: '/images/skills/postgresql.svg',
  },
  {
    name: 'Node.js',
    logo: '/images/skills/node.svg',
  },
  //   {
  //     name: 'C#',
  //     logo: '/images/skills/c-sharp.svg',
  //   },
];

export { header, about, projects, training, skills };
