import { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
	const [themeName, setThemeName] = useState('light');

	useEffect(() => {
		const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		console.log(darkMediaQuery);

		setThemeName(darkMediaQuery.matches ? 'dark' : 'light');

		darkMediaQuery.addEventListener('change', (e) => {
			setThemeName(e.matches ? 'dark' : 'light');
		});
	}, []);

	const toggleTheme = () => {
		const name = themeName === 'dark' ? 'light' : 'dark';

		localStorage.setItem('themeName', name);
		setThemeName(name);
	};

	return (
		<ThemeContext.Provider value={[{ themeName, toggleTheme }]}>
			{children}
		</ThemeContext.Provider>
	);
};

ThemeProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { ThemeProvider, ThemeContext };
